<header>
  <nav class="navbar">
    <div class="navbar-brand">
      <a href="">
        <img src="/assets/addReport/blue-logo.png" alt="">
      </a>
    </div>
  </nav>
</header>





<div class="container">
  <!-- Horizontal Form -->
  <div class="main-form">
    <div class="box-header text-center">
      <h3 class="box-title mb-4">Enter Your Details</h3>
      <p>Asterisk(*) fields are mandatory.</p>
    </div>
    <!-- /.box-header -->
    <!-- form start -->
    <form [formGroup]="addCompanyForm">
      <div class="box-body">
        <div class="row">
          <div class="col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Company Name *</label>
              <input type="text" formControlName="company_name" class="form-control" placeholder="Enter Company Name">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="addCompanyForm.get('company_name').touched && addCompanyForm.get('company_name').invalid">
                <div *ngIf="addCompanyForm.get('company_name').errors.required">Company Name is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
                        <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>

          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Admin Name*</label>
              <input type="text" formControlName="admin_name" class="form-control" placeholder="Enter Admin Name">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="addCompanyForm.get('admin_name').touched && addCompanyForm.get('admin_name').invalid">
                <div *ngIf="addCompanyForm.get('admin_name').errors.required">Admin Name is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
                        <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>

          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Admin Email *</label>
              <input type="email" formControlName="admin_email" class="form-control" placeholder="Enter Company Email">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="addCompanyForm.get('admin_email').touched && addCompanyForm.get('admin_email').invalid">
                <div *ngIf="addCompanyForm.get('admin_email').errors.required">Admin Email is required!</div>
                <div *ngIf="addCompanyForm.get('admin_email').errors.email">Admin Email is not Valid!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
                        <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>

          </div>
          <div class="col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Admin Contact *</label>
              <input type="number" formControlName="admin_contact" class="form-control"
                placeholder="Enter Company Contact Number" required>
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="addCompanyForm.get('admin_contact').touched && addCompanyForm.get('admin_contact').invalid">
                <div *ngIf="addCompanyForm.get('admin_contact').errors.required">Admin Contact is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
                        <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>


          </div>



        </div>

        <div class="col-12 col-sm-offset-2 text-right">
          <button type="submit" [disabled]="!addCompanyForm.valid" (click)="getCompanyData(addCompanyForm)" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>
