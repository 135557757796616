import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  // some fields to store our state so we can display it in the UI
 idleState = "NOT_STARTED";
 countdown?: number = null;
 lastPing?: Date = null;

 // add parameters for Idle and Keepalive (if using) so Angular will inject them from the module

  public notifier: NotifierService;
  public loginForm: FormGroup;

  constructor(public router: Router,
    public fb: FormBuilder, public service: AppService, public notifierService: NotifierService, public spinner: NgxSpinnerService, public idle: Idle, public keepalive: Keepalive, public cd: ChangeDetectorRef){
    this.notifier = notifierService;
  }


  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }





  //Get Data for Login Method
  public getLoginData = () => {
    this.spinner.show();
    let x = {
      username: this.loginForm.controls['username'].value,
      password: this.loginForm.controls['password'].value
    };
    this.service.login(x).subscribe((res: any) => {
      if(res.message == 'true') {
      this.reset();
       this.spinner.hide();
       if(res.data[0].site_dealer === "school" || res.data[0].site_dealer === "school_admin")
       {
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/schools']);
       }
       else if(res.data[0].site_dealer === "company" || res.data[0].site_dealer === "company_admin"){
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/company']);
       }
       else if(res.data[0].site_dealer === "clinic"){
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/clinic']);
       }
       else if(res.data[0].site_dealer === "sub_admin"){
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/sub-admin-dashboard']);
       }
       else if(res.data[0].site_dealer === "guidepost_admin" || res.data[0].site_dealer === "guidepost"){
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/day-care']);
       }
       else
       {
        localStorage.setItem('username', this.loginForm.controls['username'].value,);
        localStorage.setItem('password', this.loginForm.controls['password'].value);
        localStorage.setItem('site_dealer', res.data[0].site_dealer);
        this.router.navigate(['/dashboard']);
       }
      }
      else {
        this.spinner.hide();
        this.notifier.notify('error', 'Username or Password Incorrect!');
      }
    });

  }





public reset=()=> {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;

  }








}
