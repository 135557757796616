<div style="background-color: #ffffff00;" class="jumbotron text-center">
  <h1 style="font-size: 35px; ;">Thank you!<br> You are Registered</h1>
  <i style="font-size: 50px; color: #dc3545;" class="fa fa-check-circle" aria-hidden="true"></i>
   <p class="lead"><strong>Please reach out to our next available staff member<br>Please check your email for more info </strong></p>

   <hr>
   <!-- <p>
     Having trouble? <a href="">Contact us</a>
   </p> -->
   <p class="lead">
     <a class="btn btn-danger btn-sm" routerLink="/addReport" role="button">Continue to homepage</a>
   </p>
 </div>
