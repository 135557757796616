import { Pipe, PipeTransform } from '@angular/core';
import { from } from 'rxjs';


@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  transform(value, arg1? : Date , arg2? : Date)
  {
    if(arg1 && arg2)
    {
      let fromDate = new Date(arg1);
      let toDate = new Date(arg2);
      let a = value.filter(m => new Date(m.date) >= fromDate && new Date(m.date) <= toDate)
      return a;
    }

  }

  }

