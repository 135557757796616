import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCompanyComponent } from './add-report/add-company/add-company.component';
import { AddReportComponent } from './add-report/add-report.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { ThankYouComponent } from './thank-you/thank-you.component';



const routes: Routes = [

  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'addReport', component: AddReportComponent },
  { path: 'getCompanyView', component: AddCompanyComponent },
  { path: 'login', component: LoginComponent},
  { path: 'thankyou', component: ThankYouComponent},
  {
    path: "dashboard",
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "schools",
    loadChildren: () => import('./school/school.module').then(m => m.SchoolModule),
    canActivate: [AuthGuard],
  },
  {
    path: "company",
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: "clinic",
    loadChildren: () => import('./clinic/clinic.module').then(m => m.ClinicModule),
    canActivate: [AuthGuard],
  },
  {
    path: "user-status",
    loadChildren: () => import('./user-status/user-status.module').then(m => m.UserStatusModule),
    canActivate: [AuthGuard],
  },
  {
    path: "sub-admin-dashboard",
    loadChildren: () => import('./sub-admin-dashboard/sub-admin-dashboard.module').then(m => m.SubAdminDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "billings-new",
    loadChildren: () => import('./billings-new/billings-new.module').then(m => m.BillingsNewModule),
    canActivate: [AuthGuard],
  },
  {
    path: "day-care",
    loadChildren: () => import('./day-care/day-care.module').then(m => m.DayCareModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
