import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormControl} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

public addCompanyForm : FormGroup;

  constructor(public route : Router, public fb: FormBuilder) { }

  ngOnInit(): void {

    this.addCompanyForm = this.fb.group({
         company_name : ['', Validators.required],
         admin_name : ['' , Validators.required],
         admin_email : ['' , [Validators.required , Validators.email]],
         admin_contact : ['' , Validators.required]
    });

  }


  public getCompanyData=(data)=>{

     let x = {
      company_name : this.addCompanyForm.controls['company_name'].value,
      admin_name :  this.addCompanyForm.controls['admin_name'].value ,
      admin_email : this.addCompanyForm.controls['admin_email'].value,
      contact : this.addCompanyForm.controls['admin_contact'].value,
     };

     console.log("Add Company Data" , x);
     this.addCompanyForm.reset();
     this.route.navigate(['/thankyou']);



  }



}
