<header>
  <nav class="navbar">
      <div class="navbar-brand">
          <a href="">
              <img src="/assets/addReport/blue-logo.png" alt="">
          </a>
      </div>
  </nav>
</header>



<div class="container h-100 mt-4">

<notifier-container></notifier-container>


  <div class="row h-100 justify-content-center align-items-center">
      <form class="col-md-9" [formGroup] = "loginForm" >

          <div class="AppForm shadow-lg">
              <div class="row">
                  <div class="col-md-6 d-flex justify-content-center align-items-center">
                      <div class="AppFormLeft">

                          <h1>Login</h1>
                          <div class="form-group position-relative mb-4">
                              <input id="email" formControlName="username" type="text" class="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none" name="email" value="" required autocomplete="email" autofocus>
                              <i class="fa fa-user" aria-hidden="true"></i>
                          </div>
                          <div class="form-group position-relative mb-4">
                              <input id="password" formControlName="password" type="password" class="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none" name="password" required autocomplete="current-password">
                              <i class="fa fa-key" aria-hidden="true"></i>
                          </div>
                          <div class="row  mt-4 mb-4">
                              <div class="col-md-6">
                                  <!-- <div class="form-check">
                                      <input class="form-check-input" type="checkbox" name="remember" id="remember"  >
                                      <label class="form-check-label" for="remember">
                                        Remember Me
                                      </label>
                                  </div> -->
                              </div>
                              <div class="col-md-6 text-right">

                                  <!-- <a href="#">
                                        Forgot your password?
                                  </a> -->

                              </div>
                          </div>

                          <button type="submit" (click)="getLoginData()" class="btn btn-success btn-block shadow border-0 py-2 text-uppercase ">
                              Login
                          </button>

                          <!-- <p class="text-center mt-5">
                              Don't have an account?
                              <a href="#">
                                  Create your account
                              </a>

                          </p> -->

                      </div>

                  </div>
                  <div class="col-md-6">
                      <div class="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                          <h2 class="position-relative px-4 pb-3 mb-4">Welcome User</h2>
                      </div>
                  </div>
              </div>
          </div>

      </form>
  </div>
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
