import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public BaseURl = environment.BaseUrl;
  public BaseURLApi = environment.BaseUrlApi;

  constructor(public http: HttpClient) { }


  public siteDealer_varifying() {
    return !!localStorage.getItem('password');
  }

  public login = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/loginuser', obj);

  }

  public searchbig = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/searchbig', obj);
  }

  public searchBigDate = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/searchBigDate', obj);
  }


  public searchBigDateTestDate = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/siteDateSearchTestDate', obj);
  }



  public getDashboardRecords = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/indexapi', obj);

  }

  public getPatientDetail = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/singleviewapi', obj);

  }

  public getCompaniesData = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/companies', obj);

  }


  public getCompanyIndex = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/companyindex', obj);

  }

  public getSiteManager = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/allmanagers', obj);

  }


  public addManager = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/addmanager', obj);
  }


  public deleteManager = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/delete_manager', obj);
  }


  public updateManager = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/updated_manager', obj);
  }


  public DuplicateRecord = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/dublicatepatient', obj);
  }


  public EmailNegative = (obj): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURLApi + 'NegitiveEmail' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
  }


  public sendEmailViewReport = (obj): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURLApi + 'indexpdfReport' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
  }


  public deletePatient = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/deletepatient', obj);
  }

  public updatePatient = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/updatepatient', obj);
  }

  public cvs = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/cvs', obj);
  }

  public getSiteNumbers = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/allsites', obj);
  }

  public updatePatientDashboard = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/update_patient_main', obj);
  }

  public singlemainupdate = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/singlemainupdate', obj);
  }

  public editViewReport = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/updateresult', obj);
  }

  public siteSearchData = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/sitesearch', obj);
  }

  public allsites = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/allsitesadmin', obj);
  }

  public updateSite = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/updatesite', obj);
  }


  public deleteSite = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/deletesite', obj);
  }


  public addsite = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/addsite', obj);
  }


  public update_patient_main = (obj) => {
    return this.http.post<any[]>(this.BaseURl + 'api/update_patient_main', obj);
  }




  //Send Email PCR Test with BaseUrlApi
  public sendEmailViewReportTest = (data): Observable<any[]> => {

    return this.http.get<any[]>(this.BaseURLApi + 'indexpdf' + '-' + data);

  }



  public cvsmulti = (data): Observable<any[]> => {

    return this.http.get<any[]>(this.BaseURLApi + 'cvsmulti' + '-' + data);

  }



  //Doctors Module Start

  public getDoctors = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/getDoctors', obj);

  }

  public singleGetDoctor = (obj): Observable<any[]> => {

    return this.http.post<any[]>(this.BaseURl + 'api/singleGetDoctor', obj);
  }


  public multiDoctorUpdate = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/MultiDoctorMainUpdate', obj);
  }


  public deleteDoctorSingle = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleDoctorDelete', obj);
  }



  public duplicateDoctorSingle = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleDoctorDuplicate', obj);
  }


  public emailDoctorSingle = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleDoctorEmail', obj);
  }


  public doctorReportUpdate = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/doctorReportUpdate', obj);
  }


  public singleDoctorPCR = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleDoctorPCR', obj);
  }


  public MultiButtons = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/MultiButtons', obj);
  }

  public update_doctor_main = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/update_doctor_main', obj);
  }

  public allActiveDoctor = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/allActiveDoctor', obj);
  }




  //Doctors Module End





  //Billing Start

  public getBillings = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/getBillings', obj);
  }

  public singleGetBilling = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleGetBilling', obj);
  }


  public multiBillingUpdate = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/MultiBillingMainUpdate', obj);
  }


  public singleBillingDuplicate = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleBillingDuplicate', obj);
  }


  public emailBillingSingle = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleBillingEmail', obj);
  }


  public billingReportUpdate = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/billingReportUpdate', obj);
  }


  public singleBillingPCR = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singleBillingPCR', obj);
  }

  public MultiButtonsDoc = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/MultiButtonsDoc', obj);
  }

  public update_billing_main = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/update_billing_main', obj);
  }

  public allActiveBilling = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/allActiveBilling', obj);
  }

  //Billing End



  //Site Search Start
  public singlesitesearch = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURl + 'api/singlesitesearch', obj);
  }
  //Site Search End


  public cvsmultiPDF = (obj): Observable<any[]> => {
    return this.http.post<any[]>(this.BaseURLApi + 'cvsmultiPDF', obj);
  }


  public customizeSearch = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/customizeSearch', obj);
  }

  public eighteen = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/eighteen', obj);
  }



///////////////////////////////////////////////////
//////////////Companies APi Start/////////////////
//////////////////////////////////////////////////



public addCompanyRole = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addCompanyRole', obj);
}

public deleteRoleCompany = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteRoleCompany', obj);
}

public getRoleCompany = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getRoleCompany', obj);
}

  public addComapny = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/addComapny', obj);
  }

  public updatecompany = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/updatecompany', obj);
  }

  public deleteCompany = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/deleteCompany', obj);
  }

  public customizeCompanySearch = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/customizeCompanySearch', obj);
  }

  public allrecordcompany  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/allrecordcompany', obj);
  }

  public deletecompanypatient  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/deletecompanypatient', obj);
  }

  public dublicatcompanyepatient  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/dublicatcompanyepatient', obj);
  }

  public singleCompanyRecords  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/singleCompanyRecords', obj);
  }

  public companypatientresult  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/companypatientresult', obj);
  }

  public updatecompanypatient  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/updatecompanypatient', obj);
  }

  public cvscompany  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/cvscompany', obj);
  }

  public update_company_patient_main  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/update_company_patient_main', obj);
  }

  public cvscompanymulti  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURLApi + 'cvscompanymulti', obj);
  }


  public NegitiveCompanyEmail = (obj): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURLApi + 'NegitiveCompanyEmail' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
  }


  public indexCompany = (obj): Observable<any[]> => {
    return this.http.get<any[]>(this.BaseURLApi + 'indexCompany' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
  }


  public singlemainupdateC  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/singlemainupdateC', obj);
  }

  public searchbigC  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/searchbigC', obj);
  }

  public searchBigDateC  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/searchBigDateC', obj);
  }


  public siteDateSearchCTestDate  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/siteDateSearchCTestDate', obj);
  }


  public searchbigCompanyDirect  = (obj): Observable<any[]> =>{
    return this.http.post<any>(this.BaseURl + 'api/searchbigCompanyDirect', obj);
  }

/////////////////////////////////////////////////////
////////////////Companies APi End///////////////////
///////////////////////////////////////////////////


/////////////////////////////////////////////////////
////////////////Schools APi Start///////////////////
///////////////////////////////////////////////////

public addSchoolRole  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addSchoolRole', obj);
}

public getRoleSchool  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getRoleSchool', obj);
}

public deleteRole  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteRole', obj);
}



public addSchool  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addSchool', obj);
}

public getSchools  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getSchools', obj);
}

public deleteSchool  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteSchool', obj);
}

public updateSchool  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateSchool', obj);
}

public addSchoolClass  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addSchoolClass', obj);
}

public getSchoolsClass  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getSchoolsClass', obj);
}

public updateSchoolClass  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateSchoolClass', obj);
}

public deleteSchoolClass  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteSchoolClass', obj);
}

public customizeSchoolSearch  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/customizeSchoolSearch', obj);
}

public allrecordSchool  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/allrecordSchool', obj);
}


public deleteSchoolpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteSchoolpatient', obj);
}

public dublicateschoolpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/dublicateschoolpatient', obj);
}

public singleSchoolRecords  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singleSchoolRecords', obj);
}


public updateschoolpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateschoolpatient', obj);
}

public schoolpatientresult  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/schoolpatientresult', obj);
}

public cvsschoolmulti  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURLApi + 'cvsschoolmulti', obj);
}

public cvsStudent  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/cvsStudent', obj);
}

public update_school_patient_main  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/update_school_patient_main', obj);
}

public NegitiveSchoolEmail = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'NegitiveSchoolEmail' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}


public indexSchool = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'indexSchool' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}


public customizeSchoolSearchD  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/customizeSchoolSearchD', obj);
}


public allrecordSchoolD  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/allrecordSchoolD', obj);
}


public singlemainupdateS  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateS', obj);
}


public searchbigS  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigS', obj);
}

public searchBigDateS  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchBigDateS', obj);
}

public siteDateSearchSTesDate  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/siteDateSearchSTesDate', obj);
}

public searchbigSchoolDirect  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigSchoolDirect', obj);
}


public searchbigSC  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigSC', obj);
}


/////////////////////////////////////////////////////
////////////////Schools APi End/////////////////////
///////////////////////////////////////////////////




/////////////////////////////////////////////////////
////////////////Clinics APi Start///////////////////
///////////////////////////////////////////////////



public allClinics  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/allClinics', obj);
}


public addClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addClinic', obj);
}


public updateClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateClinic', obj);
}

public deleteClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteClinic', obj);
}

public customizeClinicSearch  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/customizeClinicSearch', obj);
}


public cvsClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/cvsClinic', obj);
}


public update_Clinic_patient_main  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/update_Clinic_patient_main', obj);
}


public searchbigClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigClinic', obj);
}


public searchBigDateClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchBigDateClinic', obj);
}

public deleteClinicpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteClinicpatient', obj);
}

public dublicatesClinicpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/dublicatesClinicpatient', obj);
}


public singlemainupdateClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateClinic', obj);
}


public singleClinicRecords  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singleClinicRecords', obj);
}


public updateClinicpatient  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateClinicpatient', obj);
}

public Clinicpatientresult  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/Clinicpatientresult', obj);
}


public allClinicsList  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/allClinicsList', obj);
}


public cvsschoolmultiClinic  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURLApi + 'cvsschoolmultiClinic', obj);
}

public NegitiveClinicEmail = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'NegitiveClinicEmail' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}


public indexClinic = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'indexClinic' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}


public searchbigClinicDirect  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigClinicDirect', obj);
}


/////////////////////////////////////////////////////
//////////////////Clinics APi End///////////////////
///////////////////////////////////////////////////



////////////////////////////////////////////////////////
////////////////IP Address APi Start///////////////////
//////////////////////////////////////////////////////

public addIPAddress  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addIPAddress', obj);
}

public deleteIPAddress  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteIPAddress', obj);
}

public updateIPAddress  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateIPAddress', obj);
}

public getIPAddress  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getIPAddress' , obj);
}

////////////////////////////////////////////////////////
////////////////IP Address APi End/////////////////////
//////////////////////////////////////////////////////


public addSiteRole  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addSiteRole' , obj);
}

public getSiteRolesByUser  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getSiteRolesByUser' , obj);
}

public deleteSiteRole  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteSiteRole' , obj);
}


public singleSiteRole  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singleSiteRole' , obj);
}


public getUploadedMerge = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getUploadedMerge' , obj);
}

public checkSingleMerge = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/checkSingleMerge' , obj);
}

public updateMergeRecord = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateMergeRecord' , obj);
}

public updateReportresultmerge = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateReportresultmerge' , obj);
}

public mergeAll = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/mergeAll' , obj);
}


public mergeEmailAll = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/mergeEmailAll' , obj);
}


public mergeemailPCRAll = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/mergeemailPCRAll' , obj);
}








//////////////////////////New Billings Sections Start ////////////////////////////

public singlemainupdateBilling = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateBilling' , obj);
}

public singlemainupdateSBilling = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateSBilling' , obj);
}

public singlemainupdateCBilling = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateCBilling' , obj);
}


public getTodayBillings = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getTodayBillings' , obj);
}

public deleteBillings = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteBillings' , obj);
}

public searchBigBillings = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchBigBillings' , obj);
}


public downloadBillings = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/downloadBillings' , obj);
}


//////////////////////////New Billings Section End ////////////////////////////////






//////////////////////////New DayCare Section Start ////////////////////////////////


public addDaysCaresName = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/addDaysCaresName' , obj);
}



public updateDaysCaresName = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/updateDaysCaresName' , obj);
}



public deleteDaysCaresName = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deleteDaysCaresName' , obj);
}



public getDayCares = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/getDayCares' , obj);
}



public customizeDayCareSearch = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/customizeDayCareSearch' , obj);
}


public cvsDayCare = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/cvsDayCare' , obj);
}



public update_daycare_patient_main = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/update_daycare_patient_main' , obj);
}



public deletedaycarepatient = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/deletedaycarepatient' , obj);
}



public dublicatedaycarepatient = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/dublicatedaycarepatient' , obj);
}


public singlemainupdateDayCare = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateDayCare' , obj);
}


public singlemainupdateDayCareBilling = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singlemainupdateDayCareBilling' , obj);
}


public searchbigDayCare = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigDayCare' , obj);
}


public searchBigDateDayCare = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchBigDateDayCare' , obj);
}


public siteDateSearchDCTestDate = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/siteDateSearchDCTestDate' , obj);
}


public singleDayCareRecord = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/singleDayCareRecord' , obj);
}





public daycarepatientresult = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/daycarepatientresult' , obj);
}


public searchbigDayCareDirect = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURl + 'api/searchbigDayCareDirect' , obj);
}



public cvsdaycaremulti  = (obj): Observable<any[]> =>{
  return this.http.post<any>(this.BaseURLApi + 'cvsdaycaremulti', obj);
}



public indexDayCare = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'indexDayCare' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}



public NegitiveDayCare = (obj): Observable<any[]> => {
  return this.http.get<any[]>(this.BaseURLApi + 'NegitiveDayCare' + '-' + obj.username + '-' + obj.password + '-' + obj.site_dealer + '-' + obj.id);
}




//////////////////////////New DayCare Section End //////////////////////////////////



}
