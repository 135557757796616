<header>
  <nav class="navbar">
    <div class="navbar-brand">
      <a href="">
        <img src="/assets/addReport/blue-logo.png" alt="">
      </a>
    </div>
  </nav>
</header>



<div class="container">
  <!-- Horizontal Form -->
  <div class="main-form">
    <div class="box-header text-center">
      <h3 class="box-title mb-4">Enter Your Details</h3>
      <p>Asterisk(*) fields are mandatory.</p>
    </div>
    <!-- /.box-header -->
    <!-- form start -->
    <form id="userForm" [formGroup]="covidForm">
      <div class="box-body">
        <div class="row">
          <div class="col-md-7 col-lg-7 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Site Number *</label>
              <select class="form-control" formControlName="site" style="width: 250px">
                <option disabled value="">--- Select site No ---</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>

              </select>
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1" style="width:250px"
                *ngIf="covidForm.get('site').touched && covidForm.get('site').invalid">
                <div *ngIf="covidForm.get('site').errors.required">Site Number is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
                     <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>
          </div>





          <div class="col-md-5 col-12 col-sm-12">
            <label class="control-label">Select Company </label>&nbsp;&nbsp;&nbsp;
            <div class="row">
              <div class="col-md-6">
                <label><input type="radio" id="individualCheck" (click)="hideCompany()" formControlName="company"
                    name="company" [(ngModel)]="company" value="individual"> Individual</label>
              </div>
              <div class="col-md-6">
                <label><input type="radio" id="companyCheck" (click)="showCompany()" formControlName="company"
                    name="company" [(ngModel)]="company" value="company"> Company</label>
              </div>
            </div>
          </div>






          <!--------Add company Section Start----------->
          <div class="col-md-4 col-sm-12 col-12 company-detail" *ngIf="isShowCompany">
            <label for="inputEmail3" class="control-label">Company ID</label>
            <div class="d-flex align-items-end">
              <input type="text" formControlName="company_id" class="form-control" placeholder="Enter Company Id">
              <a routerLink="/getCompanyView" class="btn btn-primary">Add</a>
            </div>
          </div>
          <!--------Add company Section End----------->






          <div class="col-md-6 col-sm-12 col-12">

            <div class="form-group">
              <label for="inputEmail3" class="control-label">First Name *</label>
              <input type="text" formControlName="first_name" class="form-control"
                placeholder="Enter Patient First Name">

              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('first_name').touched && covidForm.get('first_name').invalid">
                <div *ngIf="covidForm.get('first_name').errors.required">First Name is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
       <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->

            </div>
          </div>






          <div class="col-md-6 col-sm-12 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Last Name *</label>
              <input type="text" formControlName="last_name" class="form-control" placeholder="Enter Patient Last Name">

              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('last_name').touched && covidForm.get('last_name').invalid">
                <div *ngIf="covidForm.get('last_name').errors.required">Last Name is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
       <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->

            </div>
          </div>






          <div class="col-md-3 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Gender *</label>
              <select class="form-control" formControlName="gender">
                <option value="" disabled>Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('gender').touched && covidForm.get('gender').invalid">
                <div *ngIf="covidForm.get('gender').errors.required">Gender is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
       <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>
          </div>






          <div class="col-md-5 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Date of Birth *<span style="font-size: 12px">
                  mm/dd/yyyy</span></label>
              <input type="date" formControlName="dob" class="form-control" placeholder="Enter Patient Date of birth">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('dob').touched && covidForm.get('dob').invalid">
                <div *ngIf="covidForm.get('dob').errors.required">Date Of Birth is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
     <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>
          </div>






          <div class="col-md-4 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Email *</label>
              <input type="email" formControlName="email" class="form-control" placeholder="Enter Email Address">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('email').touched && covidForm.get('email').invalid">
                <div *ngIf="covidForm.get('email').errors.required">Email is required!</div>
                <div *ngIf="covidForm.get('email').errors.email">Email is not valid!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
     <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->

            </div>
          </div>







          <div class="col-md-3 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Address</label>
              <input type="text" class="form-control" formControlName="address"
                placeholder="Enter Patient Address">

            </div>
          </div>







          <div class="col-md-2 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">City</label>
              <input type="text" class="form-control" formControlName="city" placeholder="Enter City">
            </div>
          </div>






          <div class="col-md-3 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">State</label>
              <input type="text" class="form-control" formControlName="state" placeholder="Enter State">
            </div>
          </div>





          <div class="col-md-4 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">ID Number*<i style="font-size: 11px"> (any government issue
                  ID number)</i></label>
              <input type="text" class="form-control" formControlName="payment_id" placeholder="ID Number">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('payment_id').touched && covidForm.get('payment_id').invalid">
                <div *ngIf="covidForm.get('payment_id').errors.required">ID Number is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
       <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->

            </div>
          </div>







          <div class="col-md-6 col-12 col-sm-12">
            <label for="isurence_check" class="control-label">Do you have medical insurance? *</label>&nbsp;&nbsp;&nbsp;
            <div class="row">
              <div class="col-md-2">
                <label><input type="radio" id="chkYes" (click)="showInsurance()" formControlName="medical_Ins"
                    name="medical_Ins" [(ngModel)]="medical_Ins" value="yes">
                  Yes</label>
              </div>
              <div class="col-md-2">
                <label><input type="radio" id="chkNo" (click)="hideInsurance()" formControlName="medical_Ins"
                    name="medical_Ins" [(ngModel)]="medical_Ins" value="no" checked>
                  No</label>
              </div>
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('medical_Ins').touched && covidForm.get('medical_Ins').invalid">
                <div *ngIf="covidForm.get('medical_Ins').errors.required">Medical Insurance is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
     <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>
          </div>








          <div class="col-md-6 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Phone#</label>
              <input type="number" formControlName="phone_no" class="form-control" placeholder="Phone#">
            </div>
          </div>



        </div>






        <!-- Start Insurance Provider Section--->
        <div class="row" *ngIf="isShowInsurance">


          <div class="col-md-4 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Insurance Provider</label>
              <input type="text" formControlName="primary_ins" class="form-control" placeholder="Insurance Provider">
            </div>
          </div>



          <div class="col-md-4 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Primary Care Provider </label>
              <input type="text" name="odp" formControlName="odp" class="form-control"
                placeholder="Primary Care Provider">
            </div>
          </div>




          <div class="col-md-4 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Policy#</label>
              <input type="text" name="policy_no" formControlName="policy_no" class="form-control"
                placeholder="Policy#">
            </div>
          </div>





          <div class="col-md-6 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">or/Medicare#</label>
              <input type="text" name="medicare_no" formControlName="medicare_no" class="form-control"
                placeholder="or/Medicare#">
            </div>
          </div>





          <div class="col-md-6 col-12 col-sm-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">or/Medicaid#</label>
              <input type="text" name="medicaid_no" formControlName="medicaid_no" class="form-control"
                placeholder="or/Medicaid#">
            </div>
          </div>






          <div class="col-md-6 col-12 col-sm-12">
            <div class="form-group">
              <label class=" control-label">Insurance Card Front Pic</label>
              <div class="col-sm-6">
                <input type="file" #filepickerInsurance (change)="imgFilePickerInsurance($event)"
                  formControlName="signature" class="form-control-file">
                <p style="font-size: 9px;" class="mb-0">Upload Formats(.JPG, .PNG, .JPEG, .BMP, .GIF)</p>
                <span class="validFileText" *ngIf="validFileInsurance!=true">{{imgInsuranceName}}</span>
              </div>
            </div>
          </div>




        </div>
        <!---End Insurance Provider Section--->





        <div class="row">
          <div class="col-12">
            <p style="font-size: 8px; line-height: 8px" class="border p-2">Consent to Test: I authorize the lab to test
              and release results to the ordering provider. Health Coverage: I authorize payments be made to the lab for
              the
              laboratory services ordered by my provider. I authorize my provider and provider’s medical staff, as well
              as my health plan providing medical benefits to
              release to the lab any information needed to determine coverage for laboratory services. I understand I am
              responsible for payment of any deductible
              and co-insurance charges. If my health plan providing medical benefits makes payment for laboratory
              service to me, I understand that I am responsible for making the payment to the laboratory for services
              rendered. Self-Pay: I accept full financial responsibility for payment associated with the laboratory
              tests ordered by my provider.</p>
          </div>
        </div>




        <div class="row">
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label for="inputEmail3" class="control-label">Patient Signature *</label>
              <input type="text" formControlName="patient_signature" class="form-control" placeholder="Enter your name">
              <!----------------Validation Errors Start------------------->
              <div class="alert alert-danger px-2 py-1"
                *ngIf="covidForm.get('patient_signature').touched && covidForm.get('patient_signature').invalid">
                <div *ngIf="covidForm.get('patient_signature').errors.required">Patient Signature is required!</div>
                <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
      <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
              </div>
              <!----------------Validation Errors End------------------->
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="form-group">
              <label class=" control-label">Picture ID Card * <span style="font-size: 10px;">(State ID / Driving License
                  / Passport)</span></label>
              <div class="col-sm-6">
                <input #filepicker type="file" formControlName="image" (change)="imgFilePicker($event)"
                  class="form-control-file">
                <p style="font-size: 9px;" class="mb-0">Upload Formats(.JPG, .PNG, .JPEG, .BPM, .GIF)</p>
                <span class="validFileText" *ngIf="validFile!=true">{{imgFCnicName}}</span>
                <!----------------Validation Errors Start------------------->
                <div class="alert alert-danger px-2 py-1"
                  *ngIf="covidForm.get('image').touched && covidForm.get('image').invalid">
                  <div *ngIf="covidForm.get('image').errors.required">Picture is required!</div>
                  <!-- <div *ngIf="addCompanyForm.get('company_name').errors.minlength">First Name is too Short!</div>
      <div *ngIf="addCompanyForm.get('company_name').errors.maxlength">First Name is too Long!</div> -->
                </div>
                <!----------------Validation Errors End------------------->
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-6 mt-3 col-sm-offset-2">
          <button type="submit" [disabled]="!covidForm.valid" class="btn btn-primary"
            (click)="addReportData()">Submit</button>
        </div>
      </div>
    </form>
    <!-- <div class="d-flex">
      <img *ngIf="url != null" [src]="url" style="height : 40vh ; width: 30vw;" />
    </div> -->

  </div>
</div>
