import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CompressImageService } from '../compress-image.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.css']
})
export class AddReportComponent implements OnInit {


  public validFile: boolean = false;
  public validFileInsurance: boolean = false;
  public isShowCompany: boolean = false;
  public isShowInsurance: boolean = false;
  public covidForm: FormGroup;
  public covidFormMedIns: FormGroup;
  public imgCnicBeforeCompress = null;
  public imgCnic = null;
  public imgFCnicName = null;
  public imgInsuranceBeforeCompress = null;
  public imgInsuranceCard = null;
  public imgInsuranceName = null;
  public company;
  public medical_Ins;
  public url: string | ArrayBuffer;

  @ViewChild('filepicker', { static: false }) filepicker: ElementRef;
  @ViewChild('filepickerInsurance', { static: false }) filepickerInsurance: ElementRef;




  constructor(public router: Router, public fb: FormBuilder,
    public compressImage : CompressImageService) { }

  ngOnInit(): void {


    this.company = "individual";
    this.medical_Ins = "no";

    this.covidForm = this.fb.group({
      site: ['', Validators.required],
      company: [''],
      company_id: [''],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      address: [''],
      city: [''],
      state: [''],
      payment_id: ['', Validators.required],
      medical_Ins: ['' , Validators.required],
      primary_ins: [''],
      odp: [''],
      policy_no: [''],
      medicare_no: [''],
      medicaid_no: [''],
      phone_no: [''],
      patient_signature: ['', Validators.required],
      signature: ['' , Validators.required],
      image: ['', Validators.required]
    });

  }



  //Add Report Method for API integration
  public addReportData = () => {

    let x = {
      site : this.covidForm.controls['site'].value,
      company: this.covidForm.controls['company'].value,
      company_id: this.covidForm.controls['company_id'].value,
      first_name : this.covidForm.controls['first_name'].value,
      last_name : this.covidForm.controls['last_name'].value,
      gender : this.covidForm.controls['gender'].value,
      dob: this.covidForm.controls['dob'].value,
      email : this.covidForm.controls['email'].value,
      address : this.covidForm.controls['address'].value,
      city : this.covidForm.controls['city'].value,
      state : this.covidForm.controls['state'].value,
      payment_id: this.covidForm.controls['payment_id'].value,
      insurance_payer : this.covidForm.controls['medical_Ins'].value,
      primary_ins : this.covidForm.controls['primary_ins'].value,
      odp: this.covidForm.controls['odp'].value,
      policy_no : this.covidForm.controls['policy_no'].value,
      medicare_no : this.covidForm.controls['medicare_no'].value,
      medicaid_no : this.covidForm.controls['medicaid_no'].value,
      phone_no : this.covidForm.controls['phone_no'].value,
      patient_signature : this.covidForm.controls['city'].value,
      signature: this.imgInsuranceCard,
      image: this.imgCnic,
    };

    console.log("Add Report Data", x);

    let a = this.getFormData(x);
    this.router.navigate(['/thankyou']);

  }








  //Show Insurance Section Method
  public showInsurance = () => {
    this.isShowInsurance = true;
  }

  //Hide Insurance Section Method
  public hideInsurance = () => {
    this.isShowInsurance = false;
  }


  //Show Company Section Method
  public showCompany = () => {
    this.isShowCompany = true;
  }

  //Show Hide Section Method
  public hideCompany = () => {
    this.isShowCompany = false;
  }


//Print Form Data Values
  public getData = (data) => {
    console.log(data.value);
  }


  //Form Data Method
  public getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }


  //Insurance image file Upload
  public imgFilePickerInsurance = (event) => {

    let onInnerSection: boolean = false;

    this.imgInsuranceBeforeCompress = event.target.files[0];

    console.log(`Insurance Image size before compressed: ${this.imgInsuranceBeforeCompress.size} bytes.`);

    this.compressImage.compress(this.imgInsuranceBeforeCompress)
    .pipe(take(1))
    .subscribe(compressedImage => {
      console.log(`Insurance Image size after compressed: ${compressedImage.size} bytes.`);
      this.imgInsuranceCard = compressedImage;
      this.imgInsuranceName =this.imgInsuranceCard.name;
    });


    let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

    for (let i = 0; i <= 4; i++) {
      if (_validFileExtensions[i] == event.target.files[0].type) {
        this.imgInsuranceCard = event.target.files[0];
        let kb = event.target.files[0].size / 1000;
        let mb = kb / 1000;
        if (mb > 10) {
          this.imgInsuranceName = null;
          this.validFileInsurance = false;
          this.imgInsuranceName = "File must be less than 3 MB";
          return;
        }
        else {
          this.imgInsuranceName = null;
          onInnerSection = true;
          this.validFileInsurance = true;
          this.imgInsuranceName = event.target.files[0].name;
        }
      }
      else if (onInnerSection == false) {
        this.imgInsuranceName = null;
        this.validFileInsurance = false;
        this.imgInsuranceName = "Please Enter Valid File Type"
      }
    }

    console.log(this.imgInsuranceName);
  }




//ID Card image file Upload
  public imgFilePicker = (event) => {
    let onInnerSection: boolean = false;
    this.imgCnicBeforeCompress = event.target.files[0];

    console.log(`Image size before compressed: ${this.imgCnicBeforeCompress.size} bytes.`);

    this.compressImage.compress(this.imgCnicBeforeCompress)
    .pipe(take(1))
    .subscribe(compressedImage => {
      console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
      this.imgCnic = compressedImage;
      this.imgFCnicName =this.imgCnic.name;
    });

    if (event.target.files &&  this.imgCnic) {
      var reader = new FileReader();
      reader.readAsDataURL(this.imgCnic); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }


      let _validFileExtensions = ["image/jpg", "image/png", "image/jpeg", "image/bmp", "image/gif"];

      for (let i = 0; i <= 4; i++) {
        if (_validFileExtensions[i] == event.target.files[0].type) {
          this.imgCnic = event.target.files[0];
          let kb = event.target.files[0].size / 1000;
          let mb = kb / 1000;
          if (mb > 10) {
            this.imgFCnicName = null;
            this.validFile = false;
            this.imgFCnicName = "File must be less than 3 MB";
            return;
          }
          else {
            this.imgFCnicName = null;
            onInnerSection = true;
            this.validFile = true;
            this.imgFCnicName = event.target.files[0].name;
          }
        }
        else if (onInnerSection == false) {
          this.imgFCnicName = null;
          this.validFile = false;
          this.imgFCnicName = "Please Enter Valid File Type"
        }
      }

      console.log(this.imgFCnicName);
  }


}
